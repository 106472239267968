<template>
  <app-form-view
    app="activity"
    model="activityprofileheader"
    api-url="activity/activity-profile-header/"
    :title="$t('menu.activityProfileTemplate')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="masterActivityProfileName"
            type="select-server"
            :label="$t('fields.masterActivityProfileName')"
            :view="view"
            :binds="{
              apiUrl:
                'activity/master-data-activity-profile-header/?active=true'
            }"
            v-model="formData.master_activity_profile_id"
            @input="getDataFromMaster"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMasterActivityProfileName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_activity_profile_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="activityType"
            type="select-server"
            rules="required"
            :label="$t('fields.activityType')"
            :view="view"
            :binds="{
              apiUrl: 'activity/activity-type/?active=true'
            }"
            v-model="formData.activity_type_id"
            ref="activityType"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveActivityType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_activity_type_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.activityProfileName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Description"
            type="html-editor"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDescription"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_description"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Audit Program</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Audit Program Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  hide-edit
                  hide-delete
                  :isSelecteable="false"
                  app="audit"
                  model="audittemplateheader"
                  :headers="auditHeader"
                  :serverItems="auditItems"
                  :clientItems="auditItems"
                  :loading="loading"
                  :server-items-length="auditItemsLength"
                  @server="getAuditHeaderItem"
                >
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '../../../../components/AppTable.vue'

export default {
  name: 'activity-profile-template-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      tab: null,
      approveItems: [
        'approve_master_activity_profile_id',
        'approve_activity_type_id',
        'approve_name',
        'approve_description'
      ],
      auditItems: [],
      auditItemsLength: 0,
      loading: false
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    auditHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'audit_template_header_id.name',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'audit_template_header_id.active',
          check: true,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'activityProfileTemplateCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'activityProfileTemplateEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'activityProfileTemplateEdit',
          text: value ?? 'N/A',
          to: {
            name: 'activityProfileTemplateEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      data.activity_type_id = data.activity_type_id.id
      data.master_activity_profile_id = data.master_activity_profile_id?.id
      return data
    },
    getAuditHeaderItem() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-activity-profile?activity_profile_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditItemsLength = data.count
          this.auditItems = data.results
        })
      }
      this.loading = false
    },
    getDataFromMaster() {
      this.params = {
        id: this.formData.master_risk_profile_template_header_id
      }

      if (
        this.formData.master_activity_profile_id !== null &&
        this.formData.master_activity_profile_id !== undefined
      ) {
        this.$api({
          method: 'get',
          url:
            'activity/master-data-activity-profile-header/?id=' +
            this.formData.master_activity_profile_id?.id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$nextTick(() => {
            this.formData.activity_type_id = data.results[0].activity_type_id
            this.formData.description = data.results[0].description
            this.formData.name = data.results[0].name
            this.formData.active = data.results[0].active

            this.formData = Object.assign({}, this.formData)
            this.$refs.activityType.setDefaultServerItems(
              this.formData.activity_type_id
            )
          })
        })
      }
    }
  }
}
</script>
